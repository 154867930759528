<div class="confirm-popup">
    <owc-modal-dialog #modalDialogElement [visible]="true">

        <owc-component-header class="modal-header">

        </owc-component-header>

        <div class="modal-content">
            <owc-icon #iconElement [alt]="''" [name]="'alarm'" [status]="'warning'">
            </owc-icon>
            <br>
            <owc-typography [variant]="'title6'">
                {{ data.HEADER}}
            </owc-typography>
            <owc-typography *ngFor="let para of data.DESC; let i = index">
                    {{ para }} <br>
            </owc-typography>
        </div>
        <owc-component-footer slot="footer" class="modal-footer">

            <owc-button (click)=" onDecline()" [variant]="'secondary'"> {{ data.CANCEL_BTN}}</owc-button>
            <owc-button (click)="onAccept()"> {{ data.CONFIRM_BTN}}</owc-button>

        </owc-component-footer>
    </owc-modal-dialog>
</div>