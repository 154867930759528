import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/guard/auth.guard';
import { HomeComponent } from './dashboard/components/home/home.component';
import { AppLobbyComponent } from './dashboard/components/app-lobby/app-lobby.component';
import { UnauthorizedComponent } from './dashboard/components/unauthorized/unauthorized.component';
import { appPermissionGuard } from './auth/guard/app-permission.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: HomeComponent,
        canActivate: [appPermissionGuard],
        children: [
          {
            path: '',
            component: AppLobbyComponent
          },
          {
            path: 'enclaves',
            loadChildren: () => import('./enclave-list/enclave-list.module').then(m => m.EnclaveListModule),
          },
        ]
      },
      {
        path: 'u',
        component: UnauthorizedComponent,
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
