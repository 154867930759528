import { Component, ViewEncapsulation } from '@angular/core';
import { i18n } from 'src/i18n/i18n.en';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FooterComponent {
  i18n = i18n;

}
