import { Directive, ElementRef, Input, HostListener, Renderer2, OnDestroy, ViewContainerRef, ComponentRef } from '@angular/core';
import { OwcTooltip } from '@one/angular';
import { Components } from '@one/web-components';


@Directive({
  selector: '[appTooltip]'
})
export class TooltipDirective implements OnDestroy {
  @Input('appTooltip') text: string | undefined;
  // @Input() forceDisplayTooltip!: boolean;

  tooltipElement!: HTMLElement | null;
  compRef!: ComponentRef<OwcTooltip>| null;

  constructor(protected el: ElementRef,
    protected renderer: Renderer2,
    public vcRef: ViewContainerRef,
  ) { }

  ngOnDestroy(): void {
    this.hideTooltip();
  }

  @HostListener('mouseenter') onMouseEnter() {
      this.showTooltip();
  }

  @HostListener('mouseleave')
  @HostListener('click')
  onMouseLeave() {
    this.hideTooltip();
  }

  // private shouldDisplayTooltip(): boolean {
  //   return (this.el.nativeElement.clientWidth < this.el.nativeElement.scrollWidth) || this.forceDisplayTooltip;
  // }

  protected showTooltip() {
    if (!this.compRef) {
      const tooltipElement = this.renderer.createElement('span');
      tooltipElement.textContent = this.text;
      this.tooltipElement = tooltipElement
      const componentRef = this.vcRef.createComponent(OwcTooltip, {
        projectableNodes: [[tooltipElement]]
      })
      componentRef.setInput('anchor', this.el.nativeElement.id)
      componentRef.setInput('visible', true)
      this.compRef = componentRef
      this.renderer.appendChild(this.el.nativeElement,
        componentRef.location.nativeElement
      );
      this.compRef.changeDetectorRef.detectChanges()
    }
  }

  protected hideTooltip() {
    if (this.tooltipElement && this.compRef) {
      this.renderer.removeChild(this.el.nativeElement, this.compRef.location.nativeElement);
      this.tooltipElement = null;
      this.compRef = null
    }
  }


}


@Directive({
  selector: '[appTooltipOnEllipsis]'
})
export class TooltipOnEllipsisDirective extends TooltipDirective implements OnDestroy {
  @Input('appTooltipOnEllipsis') override text: string | undefined;

  @HostListener('mouseenter') override onMouseEnter() {
    if (this.shouldDisplayTooltip()) {
      this.showTooltip();
    }
  }

  private shouldDisplayTooltip(): boolean {
    return (this.el.nativeElement.clientWidth < this.el.nativeElement.scrollWidth) ;
  }

}
