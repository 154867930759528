import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthSession } from '@navify-platform/auth';
import { Components } from '@one/web-components';
import { NavifyService } from 'src/app/auth/services/navify.service';
import { i18n } from 'src/i18n/i18n.en';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  i18n = i18n;
  displayName!: string;
  sessionInfo!: AuthSession;

  constructor(
    private readonly nanvifyService: NavifyService,
    private readonly router: Router
  ) { }

  ngOnInit() {
    this.sessionInfo = this.nanvifyService.sessionInfo
  }

  logout(){
    this.router.navigate(['logout'])
  }


}
