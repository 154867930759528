import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { Components } from '@one/web-components';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent {

  @ViewChild('drawerElement', { static: true })
  drawerElement!: Components.OwcDrawer;

  visible: boolean = true;

}
