import { Component } from '@angular/core';
import { AuthSession } from '@navify-platform/auth';
import { NavifyService } from 'src/app/auth/services/navify.service';
import { i18n } from 'src/i18n/i18n.en';

@Component({
  selector: 'app-app-lobby',
  templateUrl: './app-lobby.component.html',
  styleUrls: ['./app-lobby.component.scss']
})
export class AppLobbyComponent {
  i18n = i18n;
  sessionInfo!: AuthSession;
  links: string[] = [
    "Introduction",
    "How to get started",
  ]

  constructor(
    private readonly nanvifyService: NavifyService,
  ) { }

  ngOnInit() {
    this.sessionInfo = this.nanvifyService.sessionInfo
  }

}
