export const environment = {
    production: false,
    platformApiUrl: 'https://api.appdevus.platform.navify.com',  // Navify Platform URL
    authUiUrl: 'https://roche.authapp.appdevus.platform.navify.com',  // Authentication application URL
    appAlias: 'kamino-platform-app-dev',  // Client application alias
    tenantAlias: 'kamino-platform-tenant-dev',  // Client tenant alias
    apiProfile: '/api/v1/users/whoami',
    apiLogout: '/api/v1/auth/logout',

    config: {
        domain: 'https://api.platform.kamino-dev.platform.navify.com/'
    },

    //enclave services
    enclaveslist: "enclave-onboarding-service/api/v1/enclaves",
    inviteadmin: "enclave-onboarding-service/api/v1/enclaves/email"
    
};
