import { NgModule } from '@angular/core';
import { HeaderComponent } from './components/header/header.component';
import { HomeComponent } from './components/home/home.component';
import { CoreModule } from '../core/core.module';
import { FooterComponent } from './components/footer/footer.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { AppLobbyComponent } from './components/app-lobby/app-lobby.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';

@NgModule({
  declarations: [
    HeaderComponent,
    HomeComponent,
    FooterComponent,
    SideMenuComponent,
    AppLobbyComponent,
    UnauthorizedComponent,
  ],
  imports: [
    CoreModule,
  ],
  exports: [
    HomeComponent,    
  ]
})
export class DashboardModule { }
