import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OneWebComponentsAngularModule } from '@one/angular';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from '../auth/interceptors/auth.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {MatDialogModule} from '@angular/material/dialog';
import { RocheEmailValidator } from '../shared/directives/roche-email-validator.directive';
import { TooltipDirective, TooltipOnEllipsisDirective } from '../shared/directives/tooltip.directive';

@NgModule({
  declarations: [
    RocheEmailValidator,
    TooltipDirective,
    TooltipOnEllipsisDirective
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    OneWebComponentsAngularModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatDialogModule,
  ],
  exports: [
    CommonModule,
    HttpClientModule,
    OneWebComponentsAngularModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatDialogModule,
    RocheEmailValidator,
    TooltipDirective,
    TooltipOnEllipsisDirective
  ],
  providers:[
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ]
})
export class CoreModule { }
