import { Directive } from '@angular/core';
import { AbstractControl, Validator, NG_VALIDATORS } from '@angular/forms';


export function RocheEmailValidatorFn(control: AbstractControl):  {[key: string]: boolean} | null  {
  const emailValidator:RegExp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const domainList = [
    '@contractors.roche.com',
    '@businesspartners.roche.com',
    '@roche.com'
  ]
  if (control.value && !emailValidator.test(control.value)) {
    return { 'invalidEmail': true };
  }
  if (control.value && domainList.find((elm:string)=>control.value.endsWith(elm))  === undefined){
    return { 'nonRocheEmail': true };
  }
  return null;
}

@Directive({
  selector: '[RocheEmailValidator]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: RocheEmailValidator,
    multi: true
  }]
})
export class RocheEmailValidator implements Validator {

  validate = RocheEmailValidatorFn
}