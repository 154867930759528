export const REQUIRED_FIELD = "The field is required";
export const i18n = {
    APP_TITLE: "Kamino",
    APP_TITLE_2: "Platform",
    HEADER: {
        PROFILE: {
            TOOLTIP: "My Account",
            TITLE: "My Settings",
            LOGOUT_BTN: "Log off"
        },
        HELP_TOOLTIP: "HELP"
    },
    FOOTER: "© 2024 F. Hoffmann-La Roche Ltd",
    SIDE_MENU: {
        "DASHBOARD": "Dashboard",
        "DASHBOARD_ICON": "home",
        "ENCLAVES": "Enclaves",
        "ENCLAVES_ICON": "business",
        "USERS": "Users",
        "USERS_ICON": "user_group",
        "CHAT": "Chat",
        "CHAT_ICON": "chat"
    },
    IDLE_POPUP: {
        HEADER: "Your session is about to expire",
        DESC: [
            "You have been inactive about 1 hour and will be logged out in 60 seconds.",
            "Would you like to continue session?"
        ],
        CONFIRM_BTN: "Yes, stay logged in",
        CANCEL_BTN: "No, logout now"

    },
    APP_LOBBY: {
        WELCOME: 'Welcome',
        CARD_1: {
            LBL: "Kamino Platform",
            DESC: [
                "Welcome to Kamino - pioneering the future of research, marked by innovative strategies and conscientious data usage.",
                "Embodying an ethos of being 'as open as possible and as closed as necessary,' \
                    we facilitate a vibrant scientific community through our interactive and intuitive interface.\
                    Kamino is your comprehensive platform, aimed at spurring scientific breakthroughs while endowing\
                    researchers and institutions with complete control over their data lifecycle.",
                "From data ingestion to analysis, publication, and reuse, Kamino streamlines and assures compliant \
                    collaboration at enclave level, prioritizing data security, confidentiality, and adherence to best practices. \
                    Through stringent governance and transparent processes, we present an accountable platform to our users and stakeholders.",
                "Join us in advancing scientific understanding and marking a global impact. Explore Kamino today, \
                    and embark on a groundbreaking journey of research collaboration and discovery."

            ],
            CNT_1: "Welcome to Kamino - pioneering the future of research, marked by innovative strategies and conscientious data usage.",
            CNT_2: "Embodying an ethos of being 'as open as possible and as closed as necessary,' \
                    we facilitate a vibrant scientific community through our interactive and intuitive interface.\
                    Kamino is your comprehensive platform, aimed at spurring scientific breakthroughs while endowing\
                    researchers and institutions with complete control over their data lifecycle.",
            CNT_3: "From data ingestion to analysis, publication, and reuse, Kamino streamlines and assures compliant \
                    collaboration at enclave level, prioritizing data security, confidentiality, and adherence to best practices. \
                    Through stringent governance and transparent processes, we present an accountable platform to our users and stakeholders.",
            CNT_4: "Join us in advancing scientific understanding and marking a global impact. Explore Kamino today, \
                    and embark on a groundbreaking journey of research collaboration and discovery."
        },
        CARD_2: {
            LBL: "Important Links",
        },
        CARD_3: {
            LBL: "Kamino Documentation",
        }
    },
    ENCLAVES: {
        TITLE: "Enclaves",
        DESC: "These are the list of all enclaves created on the Kamino Platform",
        ND: "No enclaves are available",
        NDF_FILTER: "No results found for ",
        TABLE: {
            BADGE: {
                created: "Created",
                inprogress: "Creation in progress",
                failed: "Creation Failed"
            },
            COL: {
                NAME: "Enclave Name",
                EXT_COLLAB: "External Collaboration",
                REGION: "AWS Region",
                STATUS: "Enclave Status",
                DATE: "Created on",
                ADMIN_NAME: "Enclave Admin Name",
                ADMIN_EMAIL: "Enclave Admin email",
                ACTION: "ACTION"
            }
        },
        NDF: {
            TITLE: "No search results",
            DESC: [
                "Check your search for typos or try a different search term"
            ]
        },
        IUF: {
            TITLE: "Invite Enclave Admin",
            DESC: "Provide user info to invite him as the Kamino admin of the newly created \
                    enclave on the Kamino Platform. This admin user can then define \
                        and manage the enclave-related activities.",
            SUBMIT_BTN: "Invite",
            CANCEL_BTN: "Cancel",
            INVITE_SUCCESS: "User invited successfully",
            INVITE_ERR_TA: "Technical Error. Please try again",
            FIELDS: {
                USER_NAME: {
                    NAME: 'Username (e-mail id)',
                    ERRORS: {
                        ERR_REQUIRED: REQUIRED_FIELD,
                        ERR_INVALID: "Provide a valid Roche email address",
                        ERR_ROCHE_EMAIL: "Only Roche users can be added as Enclave Admin"
                    }
                },
                FIRST_NAME: {
                    NAME: 'First Name',
                    MAX_CHAR: 1,
                    MIN_CHAR: 16,
                    ERRORS: {
                        ERR_REQUIRED: REQUIRED_FIELD,
                        ERR_CHAR_LEN: "Please enter min 1 char, max 16 characters"
                    }
                },
                LAST_NAME: {
                    NAME: 'Last Name',
                    MAX_CHAR: 1,
                    MIN_CHAR: 16,
                    ERRORS: {
                        ERR_REQUIRED: REQUIRED_FIELD,
                        ERR_CHAR_LEN: "Please enter min 1 char, max 16 characters"
                    }
                },
                ENCLAVES: {
                    NAME: "Enclave Name",
                    PLACEHOLDER: "Select a Enclave",
                    ERR_REQUIRED: REQUIRED_FIELD,
                }
            },
            TOAST: {
                SUCCESS: "User invited successfully",
                TECH_ERR: "Technical Error. Please try again"
            }
        }

    }
}
