import { Component, ViewChild } from '@angular/core';
import { Components } from '@one/web-components';
import { i18n } from 'src/i18n/i18n.en';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent {
  @ViewChild('listElement', { static: true }) listElement!: Components.OwcList;
  i18n = i18n;
  menuExpanded: boolean = false;
  selectedMenu: (string | number)[] = [i18n.SIDE_MENU.DASHBOARD]; 

  menuItems = [
    {
      icon: i18n.SIDE_MENU.DASHBOARD_ICON,
      name: i18n.SIDE_MENU.DASHBOARD,
      route: ''
    },
    {
      icon: i18n.SIDE_MENU.ENCLAVES_ICON,
      name: i18n.SIDE_MENU.ENCLAVES,
      route: 'enclaves'
    },
    {
      icon: i18n.SIDE_MENU.USERS_ICON,
      name: i18n.SIDE_MENU.USERS,
      route: 'users'
    },

  ]

  toggleSideMenu(open: boolean){
    this.menuExpanded = open
  }

  onSelectedChange(e: CustomEvent<(string | number)[]>) {
    this.selectedMenu = e.detail;
  }

}
